<template>
    <div id="Home" unselectable="on" onselectstart="return false;">
        <div class="header">
            <div class="header-div">
                <img
                    class="header-logo"
                    src="../../assets/bowinzlogo2.png"
                    alt="bowinz"
                />
            </div>
            <div class="menus">
                <ul>
                    <li :key="index" v-for="(item,index) in moduleTypes">
            <span
                :class="{ pitchOn_menus: item.isModified }"
                @click="onMenuData(item)"
            >{{ item.fCodeName }}</span
            >
                    </li>
                </ul>
            </div>
            <div class="UserName-div">
                <a-dropdown>
                    <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                        {{ username }}
                        <a-icon class="name-icon" type="caret-down"/>
                    </a>
                    <a-menu slot="overlay">
                        <a-menu-item @click="changePassword">
                            <span>修改密码</span>
                        </a-menu-item>
                        <a-menu-item @click="outLogin">
                            <span>退出登录</span>
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </div>
        </div>
        <div class="container">
            <div class="aside" v-if="ifIndex">
                <a-menu
                    :defaultSelectedKeys="[$route.name]"
                    :openKeys="openKeys"
                    mode="inline"
                    @openChange="onOpenChange"
                    @click="menuClick"
                >
                    <template v-for="item in menuData">
                        <a-menu-item :key="item.fLink" v-if="!item.children">
                            <i class="iconfont icon" v-html="item.icon"></i>
                            <span>{{ item.fModuleName }}</span>
                        </a-menu-item>
                        <sub-menu v-else :key="item.fLink" :menu-info="item"/>
                    </template>
                </a-menu>
            </div>
            <div class="main" :class="{ main1: ifIndex }">
                <a-breadcrumb v-if="ifIndex" class="main-breadcrumb">
                    <a-breadcrumb-item
                        :key="index"
                        v-for="(item, index) in breadcrumbArr"
                    >{{ item }}
                    </a-breadcrumb-item
                    >
                </a-breadcrumb>
                <div class="router-view">
                    <router-view></router-view>
                </div>
            </div>
        </div>
        <a-modal
            title="修改密码"
            :visible="visible"
            :confirm-loading="confirmLoading"
            @ok="handleOk"
            @cancel="handleCancel"
            width="350px"
        >
            <a-form-model
                ref="ruleForm"
                :model="ruleForm"
                :rules="rules"
                v-bind="layout"
            >
                <a-form-model-item has-feedback label="当前密码" prop="old">
                    <a-input
                        v-model="ruleForm.old"
                        type="password"
                        autocomplete="off"
                        placeholder="请输入当前的密码"
                    />
                </a-form-model-item>
                <a-form-model-item has-feedback label="新密码" prop="pass">
                    <a-input
                        v-model="ruleForm.pass"
                        type="password"
                        autocomplete="off"
                        placeholder="请输入你的新密码"
                    />
                </a-form-model-item>
                <a-form-model-item has-feedback label="确认新密码" prop="checkPass">
                    <a-input
                        v-model="ruleForm.checkPass"
                        type="password"
                        autocomplete="off"
                        placeholder="请再次输入你的新密码"
                    />
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import {UpdatePassword} from "../../api/Employee";
import {Menu} from "ant-design-vue";
//<a-icon :type="menuInfo.icon"/>
const SubMenu = {
    template: `
        <a-sub-menu :key="menuInfo.fLink" v-bind="$props" v-on="$listeners">
        <span slot="title">
            <i class="iconfont icon" v-html="menuInfo.fPicture"></i>
            <span>{{ menuInfo.fModuleName }}</span>
        </span>
        <template v-for="item in menuInfo.children">
            <a-menu-item v-if="!item.children" :key="item.fLink">
                <span>{{ item.fModuleName }}</span>
            </a-menu-item>
            <sub-menu v-else :key="item.fLink" :menu-info="item"/>
        </template>
        </a-sub-menu>
    `,
    methods: {
        //调用goApp1方法
        goApp1: function (item) {
            //goApp2为调用模板时写的v-on:xx
            this.$emit("goApp2", item);
        },
    },
    name: "SubMenu",
    // must add isSubMenu: true
    isSubMenu: true,
    props: {
        ...Menu.SubMenu.props,
        // Cannot overlap with properties within Menu.SubMenu.props
        menuInfo: {
            type: Object,
            default: () => ({}),
        },
    },
};
export default {
    components: {
        "sub-menu": SubMenu,
    },
    name: "Home",
    data() {
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入新密码"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        };
        let validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.ruleForm.pass) {
                callback(new Error("两次输入的密码不匹配!"));
            } else {
                callback();
            }
        };
        return {
            selectedKeys: "21",
            // 全部顶级父节点,用来控制所有父级菜单只展开其中的一项，可用遍历菜单信息进行赋值
            rootSubmenuKeys: ["WBSDecomposition", "ReportForms", "FillIn"],
            // 展开的父菜单项
            openKeys: [],
            // 选中的子菜单项
            defaultSelectedKeys: [this.$route.name],
            ifIndex: true,
            /*navigationData:[
                      {
                          id: "2",
                          name: "项目管理",
                          checked: false,
                          children: [
                              {
                                  id: "21",
                                  menuName: "工时报表",
                                  path: "ReportForms",
                                  icon: "audit",
                              },
                              {
                                  id: "22",
                                  menuName: "工时填报",
                                  path: "FillIn",
                                  icon: "solution",
                              },
                          ]
                      },
                  ],*/
            navigationData: [
                {
                    id: "1",
                    name: "首页",
                    checked: true,
                    children: [
                        {
                            id: "11",
                            menuName: "首页",
                            path: "Index",
                        },
                    ],
                },
                {
                    id: "2",
                    name: "项目管理",
                    checked: false,
                    children: [
                        {
                            id: "21",
                            menuName: "我的项目",
                            icon: "&#xe606;",
                            children: [
                                {
                                    id: "211",
                                    menuName: "项目信息",
                                    path: "ProjectInformation",
                                    icon: "file-sync",
                                },
                                {
                                    id: "212",
                                    menuName: "参与项目",
                                    path: "InvolvedInProject",
                                    icon: "file-sync",
                                },
                                {
                                    id: "213",
                                    menuName: "管理项目",
                                    path: "ManageProjects",
                                    icon: "file-sync",
                                },
                            ],
                        },
                        {
                            id: "22",
                            menuName: "工时报表",
                            icon: "&#xe648;",
                            children: [
                                {
                                    id: "221",
                                    menuName: "工时统计",
                                    path: "ReportForms",
                                    icon: "file-sync",
                                },
                                {
                                    id: "222",
                                    menuName: "工时明细统计",
                                    path: "Detailed",
                                    icon: "file-sync",
                                },
                            ],
                        },
                        {
                            id: "23",
                            menuName: "工时填报",
                            path: "FillIn",
                            icon: "&#xea46;",
                        },
                    ],
                },
                {
                    id: "3",
                    name: "时间管理",
                    checked: false,
                    children: [
                        {
                            id: "31",
                            menuName: "时间管理1",
                            path: "ReportForms1",
                            icon: "appstore",
                        },
                        {
                            id: "32",
                            menuName: "时间管理2",
                            path: "FillIn1",
                            icon: "appstore",
                        },
                    ],
                },
                {
                    id: "4",
                    name: "员工信息",
                    checked: false,
                    children: [
                        {
                            id: "41",
                            menuName: "批量更新员工信息",
                            path: "EmployeeInfo",
                            icon: "&#xe696;",
                        },
                        {
                            id: "42",
                            menuName: "员工列表",
                            path: "EmployeeList",
                            icon: "&#xe628;",
                        },
                        {
                            id: "43",
                            menuName: "部门列表",
                            path: "DepartmentList",
                            icon: "&#xe686;",
                        },
                        {
                            id: "44",
                            menuName: "职位列表",
                            path: "PositionList",
                            icon: "&#xe607;",
                        },
                    ],
                },
                {
                    id: "5",
                    name: "基础信息",
                    checked: false,
                    children: [
                        {
                            id: "51",
                            menuName: "工作日历定义",
                            path: "Calendar",
                            icon: "&#xe62d;",
                        },
                    ],
                },
                {
                    id: "6",
                    name: "权限管理",
                    checked: false,
                    children: [
                        {
                            id: "61",
                            menuName: "权限管理",
                            path: "RoleList",
                            icon: "&#xe6bd;",
                        },
                        {
                            id: "62",
                            menuName: "模块管理",
                            path: "ModuleList",
                            icon: "&#xe619;",
                        },
                    ],
                },
            ],
            menuData: [],
            breadcrumbArr: [],
            username: JSON.parse(localStorage.getItem("AdminUser")).fChineseName,
            userid: JSON.parse(localStorage.getItem("AdminUser")).fEmployeeID,
            moduleTypes: JSON.parse(localStorage.getItem("AdminUser")).moduleTypes,
            visible: false,
            confirmLoading: false,
            ruleForm: {
                old: "",
                pass: "",
                checkPass: "",
            },
            rules: {
                pass: [{validator: validatePass, trigger: "change"}],
                checkPass: [{validator: validatePass2, trigger: "change"}],
            },
            layout: {
                labelCol: {span: 8},
                wrapperCol: {span: 14},
            },
        };
    },
    // 监听,当路由发生变化的时候执行
    watch: {
        $route(to) {
            this.monitorPath(to.name);
        },
    },
    mounted() {
        // console.log(JSON.parse(localStorage.getItem("AdminUser")))
        this.monitorPath(this.$route.name);
        this.onBreadcrumbArr(this.$route.name);
    },
    created() {
        // 将从缓存中取出openKeys
        const openKeys = window.sessionStorage.getItem("openKeys");
        if (openKeys) {
            // 存在即赋值
            this.openKeys = JSON.parse(openKeys);
        }
    },
    methods: {
        // 点击菜单，路由跳转,注意的是当点击MenuItem才会触发此函数
        // eslint-disable-next-line no-unused-vars
        menuClick({item, key, keyPath}) {
            // 获取到当前的key,并且跳转
            this.$router.push({
                name: key,
            });
            this.onBreadcrumbArr(key);
        },
        onBreadcrumbArr(key) {
            this.breadcrumbArr = [];
            for (let i of this.moduleTypes) {
                for (let j of i.lSystemModuleRights) {
                    if (j.children) {
                        for (let k of j.children) {
                            if (k.fLink === key) {
                                this.breadcrumbArr = [];
                                this.breadcrumbArr[0] = i.fCodeName;
                                this.breadcrumbArr[1] = j.fModuleName;
                                this.breadcrumbArr[2] = k.fModuleName;
                            }
                        }
                    } else {
                        if (j.fLink === key) {
                            this.breadcrumbArr = [];
                            this.breadcrumbArr[0] = i.fCodeName;
                            this.breadcrumbArr[1] = j.fModuleName;
                        }
                    }
                }
            }
        },
        recursion(data, key) {
            console.log('data',data)
            if (!data.children) {
                //先判断 node节点是否含有children节点，为true 为最后一级，取id ，push到arr数组中
                key.push(data.id);
            } else {
                // false时 非最后一级，含有children，则继续循环children
                data.children.forEach((item) => this.recursion(item, key));
            }
        },
        onOpenChange(openKeys) {
            console.log("openKeys",openKeys)
            // 将当前打开的父级菜单存入缓存中
            window.sessionStorage.removeItem("openKeys");
            window.sessionStorage.setItem("openKeys", JSON.stringify(openKeys));
            //  控制只打开一个
            const latestOpenKey = openKeys.find(
                (key) => this.openKeys.indexOf(key) === -1
            );
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.openKeys = openKeys;
            } else {
                this.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        },
        changePassword() {
            this.visible = true;
        },
        handleOk() {
            this.confirmLoading = true;
            this.$refs["ruleForm"].validate((valid) => {
                if (valid) {
                    console.log(this.userid, this.ruleForm.old, this.ruleForm.pass);
                    UpdatePassword({
                        UserID: this.userid,
                        OldPassword: this.ruleForm.old,
                        NewPassword: this.ruleForm.pass,
                    }).then((res) => {
                        if (res.result == true) {
                            this.visible = false;
                            this.confirmLoading = false;
                            this.$refs["ruleForm"].resetFields();
                        } else {
                            this.$message.error("修改失败");
                            this.confirmLoading = false;
                        }
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        handleCancel() {
            this.visible = false;
            this.$refs["ruleForm"].resetFields();
        },
        onMenuData(item) {
            console.log(item);
            this.ifIndex = false;
            for (let i of this.moduleTypes) {
                i.isModified = false;
            }
            item.isModified = true;
            this.breadcrumbArr = [];
            if (item.name === "首页") {
                this.ifIndex = false;
                this.$router.push({name: item.children[0].fLink});
            } else {
                console.log("item.lSystemModuleRights[0]", item.lSystemModuleRights)
                this.menuData = item.lSystemModuleRights;
                this.rootSubmenuKeys = [];
                for (let i of this.menuData) {
                    if (i.fLink) {
                        this.rootSubmenuKeys.push(i.fLink);
                    }
                }
                if (this.menuData[0].children === undefined) {
                    this.$router.push({name: this.menuData[0].fLink});
                    this.onBreadcrumbArr(this.menuData[0].fLink);
                } else {
                    // this.openKeys = [this.menuData[0].fLink];
                    this.onOpenChange([this.menuData[0].fLink])
                    // console.log(this.openKeys)
                    this.$router.push({name: this.menuData[0].children[0].fLink});
                    this.onBreadcrumbArr(this.menuData[0].children[0].fLink);
                }
                this.ifIndex = false;
                this.$nextTick(() => {
                    this.ifIndex = true;
                });
            }
        },
        monitorPath(name) {
            this.onBreadcrumbArr(name);
            for (let i of this.moduleTypes) {
                i.isModified = false;
                for (let j of i.lSystemModuleRights) {
                    if (j.children) {
                        for (let k of j.children) {
                            if (k.fLink === name) {
                                i.isModified = true;
                                this.menuData = i.lSystemModuleRights;
                                this.rootSubmenuKeys = [];
                                for (let i of this.menuData) {
                                    if (i.fLink) {
                                        this.rootSubmenuKeys.push(i.fLink);
                                    }
                                }
                                this.ifIndex = false;
                                this.$nextTick(() => {
                                    this.ifIndex = true;
                                });
                            }
                        }
                    } else {
                        if (j.fLink === name) {
                            i.isModified = true;
                            if (i.name === "首页") {
                                this.ifIndex = false;
                            } else {
                                this.menuData = i.lSystemModuleRights;
                                this.rootSubmenuKeys = [];
                                for (let i of this.menuData) {
                                    if (i.fLink) {
                                        this.rootSubmenuKeys.push(i.fLink);
                                    }
                                }
                                this.selectedKeys = j.id;
                                this.ifIndex = false;
                                this.$nextTick(() => {
                                    this.ifIndex = true;
                                });
                            }
                        }
                    }
                }
            }
        },
        outLogin() {
            this.$router.push({name: "Login"});
        },
    },
};
</script>

<style scoped lang="stylus">
#Home {
    width: 100%;
    height: 100%;
    background-color: #F0F2F5;

    .header {
        width: 100%;
        height: 80px;
        padding-left: 32px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(270deg, #FF9942 0%, #ED6D01 100%);

        .header-div {
            display: flex;
            width: 102px;
            height: 100%;
            flex-direction: row;
            align-items: center;

            .header-logo {
                width: 102px;
                height: 64px;
            }
        }

        .menus {
            width: calc(100% - 388px);
            height: 100%;
            margin-left: 80px;

            ul {
                width: 100%;
                height: 100%;
                padding: 0;
                margin: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-wrap: wrap;

                li {
                    margin: 0 24px;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                        cursor: pointer;
                        font-size: 20px;
                        color: #FFFFFF;
                        font-weight: 400;
                    }
                }
            }
        }

        .UserName-div {
            width: 160px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            .ant-dropdown-link {
                color: white;
                font-size: 18px;
                font-weight: 400;

                .name-icon {
                    margin-left: 10px;
                }
            }
        }
    }

    .container {
        width: 100%;
        height: calc(100% - 80px);
        display: flex;
        flex-direction: row;

        .aside {
            width: 256px;
            height: 100%;
            background-color: white;
            padding-top: 50px;
            box-sizing: border-box;
        }

        .main {
            width: 100%;
            height: 100%;
            background-color: white;
            border-radius: 2px;
            overflow: hidden;

            .main-breadcrumb {
                margin: 16px 25px;
            }

            .router-view {
                width: 100%;
                height: calc(100% - 52px);
            }
        }
    }
}

.pitchOn_menus {
    font-size: 24px !important;
    font-weight: bold !important;
}

.main1 {
    width: calc(100% - 304px) !important;
    height: calc(100% - 48px) !important;
    margin: 24px;
}

/deep/ .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: linear-gradient(270deg, #FF9942 0%, #ED6D01 100%) !important;
    color: #FFFFFF;
}

.icon {
    margin-right: 6px;
}
</style>